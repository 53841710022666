import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pressao-no-vaso',
  templateUrl: './pressao-no-vaso.component.html',
  styleUrls: ['./pressao-no-vaso.component.css']
})
export class PressaoNoVasoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
