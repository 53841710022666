import { Component, OnInit } from '@angular/core';
import { Chart, registerables} from 'chart.js';

@Component({
  selector: 'app-processos',
  templateUrl: './processos.component.html',
  styleUrls: ['./processos.component.css']
})
export class ProcessosComponent implements OnInit {

  constructor() {
    Chart.register(...registerables);
   }

  ngOnInit(): void {
    var ctx = document.getElementById("speedChart") as HTMLCanvasElement;
    
    var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: ['T', 'pH', 'OD', 'Pressão', 'Aeração', 'Rotação'],
        datasets: [ {
          label: 'T1',
          data:[10, 10, 10, 10, 10, 50],
          backgroundColor: 'rgba(0, 101, 0, 0.2)',
          borderColor: 'rgba(0, 101, 0, 1)',
          borderWidth: 1
        },
        {
          label: 'T2',
          data:[10, 20, 5, 30, 10, 30],
          backgroundColor: 'rgba(0, 80, 91, 0.2)',
          borderColor: 'rgba(0, 80, 91, 1)',
          borderWidth: 1
        },
        {
          label: 'T3',
          data:[10, 30, 5, 20, 10, 30],
          backgroundColor: 'rgba(171, 80, 91, 0.2)',
          borderColor: 'rgba(171, 80, 91, 1)',
          borderWidth: 1
        },
        {
          label: 'T4',
          data:[10],
          backgroundColor: 'rgba(70, 160, 213, 0.2)',
          borderColor: 'rgba(70, 160, 213, 1)',
          borderWidth: 1
        },
        {
          label: 'T5',
          data:[10],
          backgroundColor: 'rgba(70, 48, 213, 0.2)',
          borderColor: 'rgba(0, 48, 213, 1)',
          borderWidth: 1
        },
        {
          label: 'T6',
          data:[10],
          backgroundColor: 'rgba(205, 4, 213, 0.2)',
          borderColor: 'rgba(205, 4, 213, 1)',
          borderWidth: 1
        },
        {
          label: 'T7',
          data:[10],
          backgroundColor: 'rgba(29, 220, 213, 0.2)',
          borderColor: 'rgba(29, 220, 213, 1)',
          borderWidth: 1
        },
        {
          label: 'T8',
          data:[10],
          backgroundColor: 'rgba(0, 0, 76, 0.2)',
          borderColor: 'rgba(0, 0, 76,, 1)',
          borderWidth: 1
        },
        {
          label: 'T9',
          data:[10],
          backgroundColor: 'rgba(89, 92, 76, 0.2)',
          borderColor: 'rgba(89, 92, 76, 1)',
          borderWidth: 1
        },
        {
          label: 'T10',
          data:[10],
          backgroundColor: 'rgba(242, 25, 76, 0.2)',
          borderColor: 'rgba(242, 25, 76, 1)',
          borderWidth: 1
        }
      ]
    },
    options: {
        indexAxis: 'y',
        scales: {
            y: {
                beginAtZero: true,
                stacked: true
            }, 
            x: {
              stacked: true
            }
        },
        responsive: true,
    }
  });

  var ctxTime = document.getElementById("timeChart") as HTMLCanvasElement;
  var timeChart = new Chart(ctxTime, {
    type: 'bar',
    data: {
        labels: ['Tempo'],
        datasets: [ {
          label: 'T1',
          data:[110, 288],
          backgroundColor: 'rgba(7, 9, 81, 0.2)',
          borderColor: 'rgba(7, 9, 81, 1)',
          borderWidth: 1
        }
      ]
    },
    options: {
        indexAxis: 'y',
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          }
        }
    }
  });

  }

}
