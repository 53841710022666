import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.css']
})

export class FooterComponent {

    @Input()  url;
    urlsConfig = ['/alarms', '/methods', '/registros', '/process'];

    constructor(private router: Router) {
        this.changeAtive();
        //console.warn(this.url)
    }

    changeAtive() {
        //this.removeClassAtive('principal');
        //this.removeClassAtive('controle');
        //this.removeClassAtive('graficos');
        //this.removeClassAtive('configuracoes');
        ////const isUrlConfig = this.urlsConfig.find(url => url == this.router.url) != 'undefined';
        //if (isUrlConfig) {
           // this.addClassAtive('configuracoes');
       // }
    }

    addClassAtive(elementId) {
        //var element = document.getElementById(elementId);
       // element.className = 'active-link';
    }
    removeClassAtive(elementId) {
        //var element = document.getElementById(elementId);
        //element.classList.remove('active-link');
    }
}
