import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitoramento-dioxido-carbono',
  templateUrl: './monitoramento-dioxido-carbono.component.html',
  styleUrls: ['./monitoramento-dioxido-carbono.component.css']
})
export class MonitoramentoDioxidoCarbonoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
