import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-metod',
  templateUrl: './metod.component.html',
  styleUrls: ['./metod.component.css']
})
export class MetodComponent implements OnInit {
  @Input() inputTime: string;
  @Input() inputParameter: number;
  @Input() parameter_span_value: string = 'ºC';
  @Input() time_span_value: string = 'h';
  @Input() max: number = 1000;
  @Input() min: number = 0;


  @Input() ativeParameter: boolean = false;
  defaultClassBtn: string = 'btn btn-success';
  classBtn: string;


  constructor() { 
    this.classBtn = this.defaultClassBtn + ' button-Off';
  }

  ngOnInit(): void {
  }

  changeParameter() {
    this.ativeParameter = !this.ativeParameter;

    if (this.ativeParameter) {
      this.classBtn = this.defaultClassBtn;
    } else {
      this.classBtn = this.defaultClassBtn + ' button-Off';
    }
  }

  validMinAndMaxNumber() {
		if (this.inputParameter >= this.max ) {
			this.inputParameter = this.max;
		}
		if (this.inputParameter <= this.min ) {
			this.inputParameter = this.min;
		}
	}

}
