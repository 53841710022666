import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-alarme',
  templateUrl: './form-alarme.component.html',
  styleUrls: ['./form-alarme.component.css']
})
export class FormAlarmeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
