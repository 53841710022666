import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { ControlComponent } from './pages/control/control.component';
import { GraphicsComponent } from './pages/graphics/graphics.component';
import { ConfigComponent } from './pages/config/config.component';
import { TemperaturaComponent } from './pages/temperatura/temperatura.component';
import { PotHidrogenionicoComponent } from './pages/pot-hidrogenionico/pot-hidrogenionico.component';
import { OxigenioDissolvidoComponent } from './pages/oxigenio-dissolvido/oxigenio-dissolvido.component';
import { PressaoNoVasoComponent } from './pages/pressao-no-vaso/pressao-no-vaso.component';
import { AlivioPressaoComponent } from './pages/alivio-pressao/alivio-pressao.component';
import { InjecaoArComponent } from './pages/injecao-ar/injecao-ar.component';
import { SolidosDissolvidosComponent } from './pages/solidos-dissolvidos/solidos-dissolvidos.component';
import { AgitacaoComponent } from './pages/agitacao/agitacao.component';
import { BombaAcidoComponent } from './pages/bomba-acido/bomba-acido.component';
import { BombaBaseComponent } from './pages/bomba-base/bomba-base.component';
import { NutrientesComponent } from './pages/nutrientes/nutrientes.component';
import { InoculoComponent } from './pages/inoculo/inoculo.component';
import { MonitoramentoOxigenioComponent } from './pages/monitoramento-oxigenio/monitoramento-oxigenio.component';
import { MonitoramentoDioxidoCarbonoComponent } from './pages/monitoramento-dioxido-carbono/monitoramento-dioxido-carbono.component';
import { MonitoramentoMetanoComponent } from './pages/monitoramento-metano/monitoramento-metano.component';
import { CargaSubstratoComponent } from './pages/carga-substrato/carga-substrato.component';
import { SwitchComponent } from './shared/component/switch/switch.component';
import { ComboComponent } from './shared/component/combo/combo.component';
import { MetodosComponent } from './pages/config/metodos/metodos.component';
import { AlarmesComponent } from './pages/config/alarmes/alarmes.component';
import { RegistrosComponent } from './pages/config/registros/registros.component';
import { ProcessosComponent } from './pages/config/processos/processos.component';
import { SideConfigComponent } from './pages/config/side-config/side-config.component';
import { FormAlarmeComponent } from './pages/config/form-alarme/form-alarme.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MetodComponent } from './shared/component/metod/metod.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    HomeComponent,
    ControlComponent,
    GraphicsComponent,
    ConfigComponent,
    TemperaturaComponent,
    PotHidrogenionicoComponent,
    OxigenioDissolvidoComponent,
    PressaoNoVasoComponent,
    AlivioPressaoComponent,
    InjecaoArComponent,
    SolidosDissolvidosComponent,
    AgitacaoComponent,
    BombaAcidoComponent,
    BombaBaseComponent,
    NutrientesComponent,
    InoculoComponent,
    MonitoramentoOxigenioComponent,
    MonitoramentoDioxidoCarbonoComponent,
    MonitoramentoMetanoComponent,
    CargaSubstratoComponent,
    SwitchComponent,
    ComboComponent,
    MetodosComponent,
    AlarmesComponent,
    RegistrosComponent,
    ProcessosComponent,
    SideConfigComponent,
    FormAlarmeComponent,
    MetodComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    SimpleModalModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
