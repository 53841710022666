import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oxigenio-dissolvido',
  templateUrl: './oxigenio-dissolvido.component.html',
  styleUrls: ['./oxigenio-dissolvido.component.css']
})
export class OxigenioDissolvidoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
