import { Component, OnInit } from '@angular/core';
import { Chart, registerables} from 'chart.js';


@Component({
  selector: 'app-graphics',
  templateUrl: './graphics.component.html',
  styleUrls: ['./graphics.component.css']
})
export class GraphicsComponent implements OnInit {
  
  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(){
  var ctx = document.getElementById("speedChart") as HTMLCanvasElement;
    
    var myChart = new Chart(ctx, {
    type: 'line',
    data: {
        labels: ["0", "24", "48", "72", "96", "120", "144", "168", "192", "216", "240", "264"],
        datasets: [ {
          label: 'pH',
          data:[0, 5, 10, 12, 20, 27, 30, 34, 42],
          fill: false,
          borderColor: '#51CACF',
          backgroundColor: 'transparent',
          pointBorderColor: '#51CACF',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'Temperatura',
          data:[0, 19, 15, 20, 30, 40],
          fill: false,
          borderColor: '#fbc658',
          backgroundColor: 'transparent',
          pointBorderColor: '#fbc658',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
        },
        {
          label: 'Agitação',
          data:[0, 2, 5, 9, 15, 22, 23, 30, 35, 40],
          fill: false,
          borderColor: '#6610f2',
          backgroundColor: 'transparent',
          pointBorderColor: '#6610f2',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'Aeração',
          data:[0, 3, 7, 15, 24, 28, 33, 37],
          fill: false,
          borderColor: '#fd7e14',
          backgroundColor: 'transparent',
          pointBorderColor: '#fd7e14',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'Oxigênio dissolvido',
          data:[0, 40],
          fill: false,
          borderColor: '#28a745',
          backgroundColor: 'transparent',
          pointBorderColor: '#28a745',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'EC/TDS',
          data:[0,15, 30, 40],
          fill: false,
          borderColor: '#17a2b8',
          backgroundColor: 'transparent',
          pointBorderColor: '#17a2b8',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'Pressão',
          data: [10, 12, 20, 27, 30, 34, 42],
          fill: false,
          borderColor: '#dc3545',
          backgroundColor: 'transparent',
          pointBorderColor: '#dc3545',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'Oxigênio (O2)',
          data: [5, 10, 12, 20, 27, 30, 34, 42],
          fill: false,
          borderColor: '#6c757d',
          backgroundColor: 'transparent',
          pointBorderColor: '#6c757d',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'Dióxido de Carbono (CO2) ',
          data: [34, 42],
          fill: false,
          borderColor: '#007bff',
          backgroundColor: 'transparent',
          pointBorderColor: '#007bff',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        },
        {
          label: 'Metano (CH4)',
          data: [1, 11, 22, 33],
          fill: false,
          borderColor: '#20c997',
          backgroundColor: 'transparent',
          pointBorderColor: '#20c997',
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8
        }
      ]
    },
    options: {
      responsive: true,
        scales: {
            y: {
                beginAtZero: true
            },
        },
        plugins: {
          legend: {
            position: 'left',
          },
          title: {
            display: true,
            text: 'Gráficos'
          }
        }
        
    }
  });

  }
}
