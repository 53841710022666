import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
    data = new Date();
    lightOn: boolean = false;
    lightClass: string = 'lightColorOff';
    ativeProcess = false;
    pauseProcess = false;
    processIdentifier: string;

    defaultClassBtnPauseAndReturn: string = 'btn btn-default btn-lg sid-nave';
    classBtnPauseAndReturn: string;
    labelBtnPause: string = 'Pausar';
    labelBtnRotomar: string = 'Retomar';
    labelBtnPauseRotomar: string;
    iconPause: string = 'fa fa-pause';
    iconPlay: string = 'fa fa-play';
    iconAtive:string;

    processNumber = 0;

    constructor() {
      this.classBtnPauseAndReturn = this.defaultClassBtnPauseAndReturn;
      this.labelBtnPauseRotomar = this.labelBtnPause;
      this.iconAtive = this.iconPause;
     
     }

    ngOnInit(): void {	
		setInterval(()=>{  
			this.refreshTime();
	   }, 10000);
    }

	refreshTime() {
		this.data = new Date();
	}

  lightChange() {
    this.lightOn = !this.lightOn;
    this.lightClass = this.lightOn ? 'lightColorOn' : 'lightColorOff';
  }

  initProcess() {
    this.ativeProcess = true;
    this.processNumber++;
    this.processIdentifier =  ("0000" + this.processNumber).slice(-4) + ' - ' + this.processIdentifier;
  }

  shutProcess() {
    this.ativeProcess = false;
    this.processIdentifier = '';
  }

  shutdown() {
    console.warn("DEVERÁ SER FEITO O SHUTDOWN NO RASPBERRY")
  }

  pauseAndReturProcess() {
    this.pauseProcess = !this.pauseProcess;
    if (this.pauseProcess) {
      this.classBtnPauseAndReturn =this.defaultClassBtnPauseAndReturn + ' fa-blink';
      this.iconAtive = this.iconPlay;
      this.labelBtnPauseRotomar = this.labelBtnRotomar;

    } else {
      this.classBtnPauseAndReturn = this.defaultClassBtnPauseAndReturn;
      this.iconAtive = this.iconPause;
      this.labelBtnPauseRotomar = this.labelBtnPause;
    }
  }

}
