import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit{
	
	data = new Date();

	ngOnInit(): void {	
		setInterval(()=>{  
			this.refreshTime();
		}, 10000);
	}

	refreshTime() {
		this.data = new Date();
	}

}
