import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solidos-dissolvidos',
  templateUrl: './solidos-dissolvidos.component.html',
  styleUrls: ['./solidos-dissolvidos.component.css']
})
export class SolidosDissolvidosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
