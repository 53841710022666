import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-injecao-ar',
  templateUrl: './injecao-ar.component.html',
  styleUrls: ['./injecao-ar.component.css']
})
export class InjecaoArComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
