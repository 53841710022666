import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/t',       title: 'Temperatura(T)',                   icon:'nc-bank',       class: '' },
    { path: '/ph',      title: 'Pot. Hidrogeniônico (pH)',         icon:'nc-bank',       class: '' },
    { path: '/od',      title: 'Oxigênio Dissolvido (OD)',         icon:'nc-bank',       class: '' },
    { path: '/p',       title: 'Pressão no Vaso (P)',              icon:'nc-bank',       class: '' },
    { path: '/al',      title: 'Alívio de pressão (AL)',           icon:'nc-bank',       class: '' },
    { path: '/aerecao', title: 'Injeção de AR (Aeração)',          icon:'nc-bank',       class: '' },
    { path: '/ec-tds',  title: 'Sólidos Dissolvidos (EC/TDS)',     icon:'nc-bank',       class: '' },
    { path: '/rpm',     title: 'Agitação (RPM)',                   icon:'nc-bank',       class: '' },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
