import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-combo',
  templateUrl: './combo.component.html',
  styleUrls: ['./combo.component.scss']
})
export class ComboComponent implements OnInit {

	@Input() span_value;

	@Input() numero = 0;

	@Input() max = 1000;

	@Input() min = 0;

	rateControl: FormControl;

	constructor() {
		this.rateControl = new FormControl("", [Validators.max(this.max), Validators.min(this.min)])
	}

	ngOnInit(): void {
	}

	setAdd() {
		if (this.numero >= this.max ) {
			this.numero = this.max;
		} else {
			this.numero++;
		}
	}

	setRemove() {
		if (this.numero <= this.min ) {
			this.numero = this.min;
		} else {
			this.numero--;
		}
	}

	validMinAndMaxNumber() {
		if (this.numero >= this.max ) {
			this.numero = this.max;
		}
		if (this.numero <= this.min ) {
			this.numero = this.min;
		}
	}

}
