import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitoramento-metano',
  templateUrl: './monitoramento-metano.component.html',
  styleUrls: ['./monitoramento-metano.component.css']
})
export class MonitoramentoMetanoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
