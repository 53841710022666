import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AlarmesComponent } from './pages/config/alarmes/alarmes.component';
import { ConfigComponent } from './pages/config/config.component';
import { FormAlarmeComponent } from './pages/config/form-alarme/form-alarme.component';
import { MetodosComponent } from './pages/config/metodos/metodos.component';
import { ProcessosComponent } from './pages/config/processos/processos.component';
import { RegistrosComponent } from './pages/config/registros/registros.component';
import { GraphicsComponent } from './pages/graphics/graphics.component';
import { HomeComponent } from './pages/home/home.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }]},
  { 
    path: 'home', 
    component: HomeComponent 
  },
  { 
    path: 'graphics', 
    component: GraphicsComponent 
  },
  { 
    path: 'config', 
    component: AlarmesComponent 
  },
  { 
    path: 'alarms', 
    component: AlarmesComponent 
  },
  { 
    path: 'new-alarm', 
    component: FormAlarmeComponent 
  },
  { 
    path: 'methods', 
    component: MetodosComponent 
  },
  { 
    path: 'registros', 
    component: RegistrosComponent 
  },
  { 
    path: 'process', 
    component: ProcessosComponent 
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
]
