import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  	data = new Date();

  	ngOnInit(): void {	
		setInterval(()=>{  
			this.refreshTime();
	   }, 10000);
    }

	refreshTime() {
		this.data = new Date();
	}
}
